import Parse from 'parse'
import moment from 'moment'

class Portfolio extends Parse.Object {
  constructor() {
    super('Portfolio');
  }

  get id() {
    return this.id
  }

  get name() {
    return this.get('name')
  }

  get client_name() {
    return this.get('client_name')
  }

  get description() {
    return this.get('description')
  }

  get text() {
    return this.get('text')
  }

  get client_external_link() {
    return this.get('client_external_link')
  }

  get image() {
    try {
      return this.get('image').url()
    } catch (e) {
      return ''
    }
  }

  get createdAt () {
    return moment(this.get('createdAt')).format("DD MMMM YYYY, HH:mmA")
  }

  get updatedAt () {
    return moment(this.get('updatedAt')).format("DD MMMM YYYY, HH:mmA")
  }

  get published () {
    return this.get('published') || false
  }

  static get(id) {
    let query = new Parse.Query(this)
    query.get(id)

    return query.find()
  }

  static find (page = 1, search = '') {
    const limit = 25

    let query = new Parse.Query(this)
    query.descending('createdAt')
    query.limit(limit)
    query.skip((page - 1) * limit)

    if (!!search && search.length) {
      query.fullText('name', search)
    }

    return query.find()
  }

  static images(Portfolio) {
    let relation = Portfolio.relation('images')
    return relation.query().find()
  }

  static addImage (Portfolio, asset) {
    let relation = Portfolio.relation('images')
    relation.add(asset)
    return Portfolio.save()
  }

  static removeImage (Portfolio, asset) {
    let relation = Portfolio.relation('images')
    relation.remove(asset)
    return Portfolio.save()
  }

  static async portfoliosCount () {
    let query = new Parse.Query(this)
    return query.count()
  }
}

export default Portfolio