import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'

import './plugins/bootstrap-vue'
import './plugins/parse'

import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {});

import VueCookies from 'vue-cookies'
Vue.use(VueCookies, {
  expires: '1y'
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
