const Parse = require('parse');

const Client = require('../models/Client')
Parse.Object.registerSubclass('Client', Client.default)

const User = require('../models/User')
Parse.Object.registerSubclass('_User', User.default)

const Review = require('../models/Review')
Parse.Object.registerSubclass('Review', Review.default)

const Asset = require('../models/Asset')
Parse.Object.registerSubclass('Asset', Asset.default)

const Confirmation = require('../models/Confirmation')
Parse.Object.registerSubclass('Confirmation', Confirmation.default)

const Portfolio = require('../models/Portfolio')
Parse.Object.registerSubclass('Portfolio', Portfolio.default)

const Image = require('../models/Image')
Parse.Object.registerSubclass('Image', Image.default)

const Post = require('../models/Post')
Parse.Object.registerSubclass('Post', Post.default)

const PostCategory = require('../models/PostCategory')
Parse.Object.registerSubclass('PostCategory', PostCategory.default)

const Contact = require('../models/Contact')
Parse.Object.registerSubclass('Contact', Contact.default)

Parse.serverURL = 'https://parseapi.back4app.com/'
Parse.initialize("azzrbw4EImZgwE4FEiFClMXPCM6ksmTEGKOBtb5T", "6hvfQnq8flTbMTPheBpqeUxpkVQE1TJbBy3GKLuC");

export default Parse