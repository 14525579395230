import Parse from 'parse'

class Asset extends Parse.Object {
  constructor() {
    super('Asset');
  }

  get id() {
    return this.id
  }

  get title() {
    return this.get('title')
  }

  get description() {
    return this.get('description')
  }

  get download_url() {
    return this.get('download_url')
  }

  get thumbnail() {
    try {
      return this.get('thumbnail').url()
    } catch (e) {
      return ''
    }
  }

  static get(id) {
    let query = new Parse.Query(this)
    query.get(id)

    return query.find()
  }
}

export default Asset