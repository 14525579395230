import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'

import Parse from 'parse'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      name: 'login'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    },
    beforeEnter: (to, from, next) => {
      if (Parse.User.current() != null) {
        next({ name: 'dashboard' })
      }

      next();
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/clients',
    name: 'clients',
    component: () => import('../views/Clients.vue')
  },
  {
    path:'/client/:client_id',
    name: 'client',
    component: () => import('../views/Client.vue')
  },
  {
    path: '/client/:client_id/review/:review_id',
    name: 'review',
    component: () => import('../views/Review.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users.vue')
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('../views/Portfolio.vue')
  },
  {
    path: '/portfolio/:portfolio_id',
    name: 'portfolio_detail',
    component: () => import('../views/PortfolioDetail.vue')
  },
  {
    path: '/posts',
    name: 'posts',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/posts/:post_id',
    name: 'post_detail',
    component: () => import('../views/Post.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if(!to.matched.some(record => record.meta.guest) && !Parse.User.current()) {
    next({name: "login", query: { redirect: window.location.origin + to.path }, params: to.params });
  }
  
  next();
})

export default router
