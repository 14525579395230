import Parse from 'parse'
import moment from 'moment'

class Review extends Parse.Object {
  constructor() {
    super('Review');
  }

  get id() {
    return this.id
  }

  get title() {
    return this.get('title')
  }

  get image() {
    try {
      return this.get('image').url()
    } catch (e) {
      return ''
    }
  }

  get description() {
    return this.get('description')
  }

  get createdAt () {
    return moment(this.get('createdAt')).format("DD MMMM YYYY")
  }

  get updatedAt () {
    return moment(this.get('updatedAt')).format("DD MMMM YYYY, HH:mmA")
  }

  static get(id) {
    let query = new Parse.Query(this)
    query.get(id)

    return query.find()
  }

  static assets(Review) {
    let relation = Review.relation('assets')
    let query = relation.query()
    query.descending('createdAt')
    return query.find()
  }

  static addAsset (Review, asset) {
    let relation = Review.relation('assets')
    relation.add(asset)
    return Review.save()
  }

  static removeAsset (Review, asset) {
    let relation = Review.relation('assets')
    relation.remove(asset)
    return Review.save()
  }

  static confirmations(Review) {
    let relation = Review.relation('confirmations')
    let query = relation.query()
    query.include('confirmed_by')
    query.descending('createdAt')
    return query.find()
  }

  static confirmationsCount(Review) {
    let relation = Review.relation('confirmations')
    let query = relation.query()
    return query.count()
  }

  static addConfirmation (Review, confirmation) {
    let relation = Review.relation('confirmations')
    relation.add(confirmation)
    return Review.save()
  }
}

export default Review