import Parse from 'parse'
import moment from 'moment'

class Confirmation extends Parse.Object {
  constructor() {
    super('Confirmation');
  }

  get id() {
    return this.id
  }

  get assets() {
    return this.get('assets')
  }

  get confirmed_by() {
    return this.get('confirmed_by')
  }

  get createdAt () {
    return moment(this.get('createdAt')).format("DD MMMM YYYY, HH:mmA")
  }

  get updatedAt () {
    return moment(this.get('updatedAt')).format("DD MMMM YYYY, HH:mmA")
  }

  static get(id) {
    let query = new Parse.Query(this)
    query.get(id)
    query.include('confirmed_by')

    return query.find()
  }
}

export default Confirmation