import Parse from 'parse'
import moment from 'moment'

class Contact extends Parse.Object {
  constructor() {
    super('Contact');
  }

  get id() {
    return this.id
  }

  get name() {
    return this.get('name')
  }

  get email() {
    return this.get('email')
  }

  get phone() {
    return this.get('phone')
  }

  get subject() {
    return this.get('subject')
  }

  get message() {
    return this.get('message')
  }

  get createdAt () {
    return moment(this.get('createdAt')).format("DD MMMM YYYY, HH:mmA")
  }

  static async find (page = 1) {
    console.log('find');
    const limit = 25

    let query = new Parse.Query(this)
    query.limit(limit)
    query.skip((page - 1) * limit)

    return query.find()
  }

  static async contactCount () {
    let query = new Parse.Query(this)
    return query.count()
  }
}

export default Contact