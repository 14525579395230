import Parse from 'parse'

class Client extends Parse.Object {
  constructor() {
    super('Client');
  }

  get id() {
    return this.id
  }

  get name() {
    return this.get('name')
  }

  get description() {
    return this.get('description')
  }

  get image() {
    try {
      return this.get('image').url()
    } catch (e) {
      return ''
    }
  }

  static get(id) {
    let query = new Parse.Query(this)
    query.get(id)

    return query.find()
  }

  static find (page = 1, is_admin) {
    const limit = 25

    let query = new Parse.Query(this)
    query.limit(limit)
    query.skip((page - 1) * limit)

    if (!is_admin) {
      query.equalTo('users', Parse.User.current())
    }

    return query.find()
  }

  static users(Client) {
    let relation = Client.relation('users')
    return relation.query().find()
  }

  static addUser (Client, user) {
    let relation = Client.relation('users')
    relation.add(user)
    return Client.save()
  }

  static removeUser (Client, user) {
    let relation = Client.relation('users')
    relation.remove(user)
    return Client.save()
  }

  static reviews (Client) {
    let relation = Client.relation('reviews')
    return relation.query().find()
  }

  static addReview (Client, review) {
    let relation = Client.relation('reviews')
    relation.add(review)
    return Client.save()
  }
}

export default Client