import Parse from 'parse'
import moment from 'moment'

class PostCategory extends Parse.Object {
  constructor() {
    super('PostCategory');
  }

  get id() {
    return this.id
  }

  get name() {
    return this.get('name')
  }

  static get(id) {
    let query = new Parse.Query(this)
    query.get(id)

    return query.find()
  }

  static find (page = 1, search = '') {
    const limit = 25

    let query = new Parse.Query(this)
    query.descending('createdAt')
    query.limit(limit)
    query.skip((page - 1) * limit)

    if (!!search && search.length) {
      query.fullText('title', search)
    }

    return query.find()
  }

  static async search (text = '') {
    console.log('search');
    let isSearch = text != null && text.length > 0
    const limit = 25

    let query = new Parse.Query(this)
    if (isSearch)
      query.fullText('name', text)
    query.limit(limit)

    return query.find()
  }
}

export default PostCategory