import Parse from 'parse'

class Image extends Parse.Object {
  constructor() {
    super('Image');
  }

  get id() {
    return this.id
  }

  get title() {
    return this.get('title')
  }

  get description() {
    return this.get('description')
  }

  get image() {
    try {
      return this.get('image').url()
    } catch (e) {
      return ''
    }
  }

  static get(id) {
    let query = new Parse.Query(this)
    query.get(id)

    return query.find()
  }
}

export default Image