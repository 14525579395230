import Parse from 'parse'

class User extends Parse.User {
  constructor() {
    super('_User');
  }

  get id() {
    return this.id
  }

  get username() {
    return this.get('username')
  }

  get email() {
    return this.get('email')
  }

  get name() {
    return this.get('name')
  }

  get last_name() {
    return this.get('last_name')
  }

  static async find (page = 1) {
    console.log('find');
    const limit = 25

    let query = new Parse.Query(this)
    query.limit(limit)
    query.skip((page - 1) * limit)

    return query.find()
  }

  static async usersCount () {
    let query = new Parse.Query(this)
    return query.count()
  }

  static async search (text = '') {
    console.log('search');
    let isSearch = text != null && text.length > 0
    const limit = 25

    let nameQuery = new Parse.Query(this)
    let emailQuery = new Parse.Query(this)

    nameQuery.startsWith('name', text)
    emailQuery.startsWith('username', text)

    let query = isSearch ? Parse.Query.or(nameQuery, emailQuery) : new Parse.Query(this)
    query.limit(limit)

    return query.find()
  }
}

export default User