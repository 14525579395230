<template>
<!-- Jumbotron -->
  <div class="container py-4">
    <div class="row g-0 align-items-center">
      <div class="col-lg-6 mb-5 mb-lg-0">
        <div class="card cascading-right" style="
            background: hsla(0, 0%, 100%, 0.55);
            backdrop-filter: blur(30px);
            ">
          <div class="card-body p-5 shadow-5 text-center">
            <h2 class="fw-bold mb-5">Inicia sesión</h2>
            <form @submit.prevent="login">
              <!-- Email input -->
              <div class="form-outline mb-4 text-left">
                <label class="form-label" for="form3Example3">Correo electrónico</label>
                <input type="email" id="form3Example3" class="form-control" v-model="email" />
                <span class="text-danger" style="font-size:13px;" v-if="resetError">Introduce tu correo electrónico</span>
              </div>

              <!-- Password input -->
              <div class="form-outline mb-4 text-left">
                <label class="form-label" for="form3Example4">Contraseña</label>
                <input type="password" id="form3Example4" class="form-control" v-model="password" />
              </div>

              <!-- Submit button -->
              <button type="submit" class="btn btn-primary btn-block mb-4 btn-lg" :disabled="formError || loading">
                Entrar
              </button>

              <p class="text-center" style="font-size:13px;cursor:pointer;" @click="resetPassword">Olvidé mi contraseña</p>
            </form>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-5 mb-lg-0">
        <img src="https://mdbootstrap.com/img/new/ecommerce/vertical/004.jpg" class="w-100 rounded-4 shadow-4"
          alt="" />
      </div>
    </div>
  </div>
  <!-- Jumbotron -->
</template>

<script>
import Parse from 'parse'

export default {
  data () {
    return {
      email: '',
      password: '',
      resetError: false,
      loading: false
    }
  },
  created () {
    if (Parse.User.current() != null) {
      this.$router.push('/dashboard')
    }
  },
  computed: {
    formError () {
      return this.email.length < 1 || this.password.length < 5
    }
  },
  methods: {
    async login () {
      if (!this.email.length || this.password.length < 5) return

      this.loading = true
      try {
        const user = await Parse.User.logIn(this.email, this.password)
        if (user) {
          this.$bvToast.toast(`Redirigiendo a tu dashboard...`, {
            title: 'Bienvenid@ de nuevo',
            variant: 'success',
            solid: true
          })

          setTimeout(() => {
            this.$router.push('/dashboard')
          }, 2000)
        } else {
          this.$bvToast.toast(`Ocurrió un error. Intenta de nuevo.`, {
            title: 'Error',
            variant: 'danger',
            solid: true
          })
        }
      } catch (e) {
        console.log(e.message)
        let error_message
        if (e.message.includes('Invalid')) {
          error_message = 'Usuario o contraseña incorrectos.'
        } else {
          error_message = 'Ocurrió un error. Intenta de nuevo.'
        }

        this.$bvToast.toast(`${error_message}`, {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }

      this.loading = false
    },
    async resetPassword () {
      if (!this.email.length) {
        this.resetError = true
        return
      } else {
        this.resetError = false
      }

      this.loading = true
      
      try {
        let reset = await Parse.User.requestPasswordReset(this.email)
        console.log(reset)
        this.$bvToast.toast(`Correo de cambio de contraseña enviado con éxito`, {
          title: 'Enviado',
          variant: 'success',
          solid: true
        })
      } catch (e) {
        console.error(e.message)
        this.$bvToast.toast(`Ocurrió un error enviando correo de cambio de contraseña`, {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }

      this.loading = false
    }
  },
  watch: {
    email (val) {
      if (val && val.length) {
        this.resetError = false
      }
    }
  }
}
</script>

<style>

</style>