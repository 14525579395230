import Parse from 'parse'
import moment from 'moment'

class Post extends Parse.Object {
  constructor() {
    super('Post');
  }

  get id() {
    return this.id
  }

  get title() {
    return this.get('title')
  }

  get short_text() {
    return this.get('short_text')
  }

  get content() {
    return this.get('content')
  }

  get author() {
    return this.get('author')
  }

  get tags() {
    return this.get('tags') || []
  }

  get seen_count() {
    return this.get('seen_count') || 0
  }

  get published () {
    return this.get('published') || false
  }

  get image() {
    try {
      return this.get('image').url()
    } catch (e) {
      return ''
    }
  }

  get createdAt () {
    return moment(this.get('createdAt')).format("DD MMMM YYYY, HH:mmA")
  }

  get updatedAt () {
    return moment(this.get('updatedAt')).format("DD MMMM YYYY, HH:mmA")
  }

  static get(id) {
    let query = new Parse.Query(this)
    query.get(id)

    return query.find()
  }

  static find (page = 1, search = '') {
    const limit = 25

    let query = new Parse.Query(this)
    query.descending('createdAt')
    query.limit(limit)
    query.skip((page - 1) * limit)

    if (!!search && search.length) {
      query.fullText('title', search)
    }

    return query.find()
  }

  static categories(Post) {
    let relation = Post.relation('categories')
    return relation.query().find()
  }

  static addCategory (Post, category) {
    let relation = Post.relation('categories')
    relation.add(category)
    return Post.save()
  }

  static removeCategory (Post, category) {
    let relation = Post.relation('categories')
    relation.remove(category)
    return Post.save()
  }

  static async postsCount () {
    let query = new Parse.Query(this)
    return query.count()
  }
}

export default Post